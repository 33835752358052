import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import StoreContext from '../contexts/StoreContext';

const ProtectRole = ({ role }) => {
    const { state } = useContext(StoreContext);
    const { userInfo } = state;

    if (userInfo?.role === role) {
        return <Outlet />;
    } else {
        return <Navigate to='/dashboard/unable-access' />;
    }
}

export default ProtectRole