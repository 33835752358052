import React from 'react'
import UserForm from './UserForm'

const AddUser = () => {

  return (
    <UserForm isEditMode={false}/>
  )
}

export default AddUser