export const setUserInfo = (userInfo, accessToken, refreshToken) => ({
    type: 'SET_USER_INFO',
    payload: { userInfo, accessToken, refreshToken }
  });
  
  export const clearUserInfo = () => ({
    type: 'CLEAR_USER_INFO'
  });


  export const setSubscriberInfo = (subscriberInfo, accessToken, refreshToken) => ({
    type: 'SET_SUBSCRIBER_INFO',
    payload: { subscriberInfo, accessToken, refreshToken }
  });
  
  export const clearSubscriberInfo = () => ({
    type: 'CLEAR_SUBSCRIBER_INFO'
  });
  