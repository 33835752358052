import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import UserForm from "./UserForm";

const UpdateUser = () => {
    const { userId } = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            // const accessToken = localStorage.getItem("accessToken");
            // const refreshToken = localStorage.getItem("refreshToken");

            // if (!accessToken || !refreshToken) {
            //     toast.error("Missing authentication tokens");
            //     return;
            // }
            try {
                const response = await axios.get(`https://api.hawanews.online/api/v1/users/${userId}` 
                // {
                //     headers: {
                //         Authorization: `Bearer ${accessToken}`,
                //         "Content-Type": "multipart/form-data",
                //     },
                //     params: {
                //         refreshToken: refreshToken,
                //     },
                //     withCredentials: true
                // }
                );

            } catch (error) {
                console.error('Error fetching user data:', error);
                toast.error('Error fetching user data');
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchUserData();
        }
    }, [userId]);


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <UserForm isEditMode={true} />
    );
};

export default UpdateUser