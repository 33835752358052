import React, { useContext, useEffect, useState } from 'react';
import profilepic from './profile.png';
import './Header.scss';
import StoreContext from '../contexts/StoreContext';
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from './Sidebar'; // Import your Sidebar component

const Header = () => {
  const { state } = useContext(StoreContext);
  const [user, setUser] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); // State for sidebar visibility
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const storedUser = localStorage.getItem('userInfo');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
  };

  if (!user) return <div>Please Login</div>;

  return (
    <div className='head-wrapper'>
      <div className="mobile-sidebar">
        <GiHamburgerMenu onClick={toggleSidebar} /> {/* Toggle sidebar on click */}
      </div>
      <div className="search">
        <input type="text" placeholder='search' />
      </div>
      <div className="sm-profile">
        <div className='info'>
          <p>Welcome {user.fullName}</p>
          <p>Role: {user.role}</p>
        </div>
        <img src={user.avatar || profilepic} alt="AVT" />
      </div>
      {isMobile && <Sidebar isVisible={isSidebarVisible} onClose={toggleSidebar} />}
    </div>
  );
};

export default Header;
