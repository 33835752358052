import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StoreProvider from "./contexts/StoreProvider.jsx";
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StoreProvider>
      <>
        <App />
        <Toaster />
      </>
  </StoreProvider>
);
reportWebVitals();
