import React from 'react'
import NewsContent from '../components/NewsContent'

const News = () => {
  

  return (
    <div className='news-wrapper'>
      <NewsContent />
    </div>
  )
}

export default News