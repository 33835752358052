import React, { useEffect } from 'react'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import './MainLayout.scss'
import { useNavigate } from 'react-router-dom'


const MainLayout = () => {

    const navigate = useNavigate()

    useEffect(() => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      if (!(accessToken && refreshToken)) {
        navigate("/login")
      }
    })

    return (
        <div className='main-wrapper'>
            <Sidebar />
            <div className='info-header'>
                <Header />
                <div>
                    <div className='outlet'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainLayout