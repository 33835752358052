import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Gallery.scss';
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineDelete, AiOutlineEllipsis } from "react-icons/ai";

const Gallery = ({ setShowGallery, onSelectImage }) => {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [visibleDropdown, setVisibleDropdown] = useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`https://api.hawanews.online/api/v1/images`);
                // Sort images by creation timestamp in descending order
                const sortedImages = response.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setImages(sortedImages);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        fetchImages();
    }, []);

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('image', file);

        setUploading(true);
        setUploadError(null);

        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");

        try {
            const response = await axios.post(`https://api.hawanews.online/api/v1/images`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    refreshToken: refreshToken,
                },
                withCredentials: true
            });
            setImages([response.data.data, ...images]);
            setUploading(false);
        } catch (error) {
            setUploadError(error);
            setUploading(false);
        }
    };

    const handleSelectImage = (image) => {
        setSelectedImage(image);
        if (onSelectImage) {
            onSelectImage(image);
        }
    };

    const handleDeleteImage = async (imageId) => {
        const accessToken = localStorage.getItem("accessToken");

        try {
            await axios.delete(`https://api.hawanews.online/api/v1/images/${imageId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            setImages(images.filter(image => image._id !== imageId));
        } catch (error) {
            console.error("Error deleting image:", error);
        }
    };

    const toggleDropdown = (event, imageId) => {
        event.stopPropagation();
        setVisibleDropdown(visibleDropdown === imageId ? null : imageId);
    };

    if (isLoading) {
        return <div>Loading images...</div>;
    }

    if (error) {
        return <div>Error fetching images: {error.message}</div>;
    }

    return (
        <div className='gallery-wrapper'>
            <div className='gallery-list'>
                <div className='toggle'>
                    <div className='upload'>
                        <label htmlFor="img">
                            <div className='upload-btn'>
                                {/* <span><BsUpload /></span> */}
                                <span>Upload Image</span>
                            </div>
                        </label>
                        <input type="file" id='img' onChange={handleUpload} />
                    </div>
                    <div className='close-btn' onClick={() => setShowGallery(false)}>
                        <AiOutlineClose />
                    </div>
                </div>
                {uploading && <div>Uploading image...</div>}
                {uploadError && <div>Error uploading image: {uploadError.message}</div>}
                <div className='image-gallery'>
                    {images.map(image => (
                        <div
                            key={image._id}
                            className={`image-item ${selectedImage === image ? 'selected' : ''}`}
                            onClick={() => handleSelectImage(image)}
                        >
                            <img src={image.url} alt={image.description || 'Gallery Image'} />
                            <div className='dropdown'>
                                <button
                                    className='dropdown-btn'
                                    onClick={(event) => toggleDropdown(event, image._id)}
                                >
                                    <AiOutlineEllipsis />
                                </button>
                                {visibleDropdown === image._id && (
                                    <div className='dropdown-content'>
                                        <button
                                            className='delete-btn'
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleDeleteImage(image._id);
                                            }}
                                        >
                                            <AiOutlineDelete /> Delete
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Gallery;
