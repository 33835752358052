import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Gallery from "../components/Gallery";
import Tags from "../components/Tags";
import toast from "react-hot-toast";
import axios from "axios";
import "./Postform.scss";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const PostForm = ({ isEditMode = false }) => {
    const [initialData, setInitialData] = useState({});
    const [showGallery, setShowGallery] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [featureImage, setFeatureImage] = useState("");
    const [tags, setTags] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectingFor, setSelectingFor] = useState(null);
    const [name, setName] = useState("");
    const [uploadedImages, setUploadedImages] = useState([]);
    const [isPublished, setIsPublished] = useState(true);
    const { postId } = useParams();
    const navigate = useNavigate();
    const quillRef = useRef(null);

    useEffect(() => {
        fetchUploadedImages();
    }, []);

    useEffect(() => {
        if (postId) {
            fetchPostData();
        }
    }, [postId]);

    const fetchUploadedImages = async () => {
        try {
            const response = await axios.get(`https://api.hawanews.online/api/v1/images`);
            setUploadedImages(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error("Error fetching uploaded images:", error);
            toast.error("Error fetching uploaded images");
        }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await axios.get(`https://api.hawanews.online/api/v1/categories`);
            setCategories(response.data);
        };
        fetchCategories();
    }, []);


    const fetchPostData = async () => {
        try {
            const response = await axios.get(
                `https://api.hawanews.online/api/v1/posts/${postId}`
            );
            const post = response.data.data.post;
            setInitialData({
                title: post.title || "",
                content: post.content || "",
                featureImage: post.featureImage || "",
                tags: Array.isArray(post.tags) ? post.tags : [],
                category: Array.isArray(post.categories) ? post.categories.map(cat => cat._id) : [], // Map category objects to their ids
                isPublished: post.isPublished,
            });
            setTitle(post.title);
            setContent(post.content);
            setFeatureImage(post.featureImage);
            setSelectedCategories(Array.isArray(post.categories) ? post.categories.map(cat => cat._id) : []); // Map category objects to their ids
            setTags(Array.isArray(post.tags) ? post.tags : []);
            setIsPublished(post.setIsPublished);
        } catch (error) {
            console.error("Error fetching post data:", error);
            toast.error("Error fetching post data");
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedCategories((prevSelected) => {
            if (!Array.isArray(prevSelected)) {
                console.error("prevSelected is not an array:", prevSelected);
                return []; // Reset to an empty array
            }
            if (checked) {
                return [...prevSelected, value];
            } else {
                return prevSelected.filter((category) => category !== value);
            }
        });
    };


    const handleAddCategory = async (event) => {
        event.preventDefault();
        try {
            const newCategory = { name };
            const response = await axios.post(
                `https://api.hawanews.online/api/v1/categories`,
                newCategory
            );
            setCategories([...categories, response.data.category]);
            setName("");
            toast.success("Category created successfully");
        } catch (error) {
            console.error("Error creating category:", error);
            toast.error("Error creating category");
        }
    };


    const handleSelectImage = (image) => {
        if (selectingFor === "feature") {
            setFeatureImage(image.url);
        } else if (selectingFor === "content" && quillRef.current) {
            const range = quillRef.current.editor.getSelection();
            const index = range ? range.index : 0;
            // Insert the image HTML at the current cursor position
            quillRef.current.editor.insertEmbed(index, "image", image.url);
        }
        setShowGallery(false);
    };


    // Modules for ReactQuill editor
    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                [{ size: ['small', 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
            ],
            handlers: {
                image: () => {
                    setShowGallery(true);
                    setSelectingFor("content");
                },
            },
        },
    }), [setShowGallery, setSelectingFor]);


    const handleSaveDraft = async (event) => {
        event.preventDefault();

        if (!title || !content) {
            toast.error("Title and description are required!");
            return;
        }

        try {
            setLoading(true);
            const formData = {
                title,
                content,
                categories: selectedCategories,
                tags: tags,
                featureImage,
                isPublished: false,
            };

            const accessToken = localStorage.getItem("accessToken");
            const refreshToken = localStorage.getItem("refreshToken");

            if (!accessToken || !refreshToken) {
                toast.error("Missing authentication tokens");
                return;
            }

            let response;
            if (isEditMode) {
                response = await axios.patch(
                    `https://api.hawanews.online/api/v1/posts/${postId}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        params: {
                            refreshToken: refreshToken,
                        },
                    }
                );
            } else {
                response = await axios.post(
                    `https://api.hawanews.online/api/v1/posts`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        params: {
                            refreshToken: refreshToken,
                        },
                    }
                );
            }

            toast.success("Draft saved successfully!");

            console.log("Draft saved successfully:", response.data);
            navigate("/dashboard/news");
        } catch (error) {
            console.error("Error saving draft:", error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(`Error: ${error.response.data.message}`);
            } else {
                toast.error(`Error saving Draft !`);
            }
        } finally {
            setLoading(false);
        }
    };

    const handlePublishDraft = async (event) => {
        event.preventDefault();

        if (!title || !content) {
            toast.error("Title and description are required!");
            return;
        }

        try {
            setLoading(true);
            const formData = {
                title,
                content,
                categories: selectedCategories,
                tags: tags,
                featureImage,
                isPublished: true,
            };

            const accessToken = localStorage.getItem("accessToken");
            const refreshToken = localStorage.getItem("refreshToken");

            if (!accessToken || !refreshToken) {
                toast.error("Missing authentication tokens");
                return;
            }

            const response = await axios.patch(
                `https://api.hawanews.online/api/v1/posts/${postId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        refreshToken: refreshToken,
                    },
                }
            );

            toast.success("Draft published successfully!");

            console.log("Draft published successfully:", response.data);
            navigate("/dashboard/news");
        } catch (error) {
            console.error("Error publishing draft:", error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(`Error: ${error.response.data.message}`);
            } else {
                toast.error(`Error publishing Draft !`);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!title || !content) {
            toast.error("Title and description are required!");
            return;
        }

        try {
            setLoading(true);
            const formData = {
                title,
                content,
                categories: selectedCategories,
                tags: tags,
                featureImage,
                isPublished: true,
            };

            const accessToken = localStorage.getItem("accessToken");
            const refreshToken = localStorage.getItem("refreshToken");

            if (!accessToken || !refreshToken) {
                toast.error("Missing authentication tokens");
                return;
            }

            let response;
            if (isEditMode) {
                response = await axios.patch(
                    `https://api.hawanews.online/api/v1/posts/${postId}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        params: {
                            refreshToken: refreshToken,
                        },
                    }
                );
                toast.success("Post updated successfully!");
            } else {
                response = await axios.post(
                    `https://api.hawanews.online/api/v1/posts`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        params: {
                            refreshToken: refreshToken,
                        },
                    }
                );
                toast.success("Post created successfully!");
            }

            console.log("Post created/updated successfully:", response.data);
            navigate("/dashboard/news");
        } catch (error) {
            console.error("Error creating/updating post:", error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(`Error: ${error.response.data.message}`);
            } else {
                toast.error(`Error ${isEditMode ? "Updating" : "Creating"} Post!`);
            }
        } finally {
            setLoading(false)
        }
    };


    return (
        <div className="news-wrapper">
            {loading && <p>Please Wait While Posting a News...</p>}
            <div className="contentarea">
                <div className="action-area">
                    <div className="title-label">
                        <p>Add News</p>
                    </div>
                    <div className="btns">
                        <button
                            type="button"
                            className="save-btn"
                            onClick={handleSaveDraft}
                        >
                            {isEditMode && !initialData.isPublished
                                ? "Update Draft"
                                : "Save Draft"}
                        </button>
                        <button
                            type="button"
                            onClick={(event) => {
                                if (isEditMode && !initialData.isPublished) {
                                    handlePublishDraft(event);
                                } else {
                                    handleSubmit(event);
                                }
                            }}
                            className="publish-btn"
                        >
                            {isEditMode && initialData.isPublished ? "Update" : "Publish"}
                        </button>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="title-des">
                        <div className="title">
                            <input
                                type="text"
                                name="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Title here"
                            />
                        </div>
                        <div className="description">
                            <div className="inputarea">
                                <ReactQuill
                                    ref={quillRef}
                                    className="editor-input"
                                    theme="snow"
                                    value={content}
                                    onChange={setContent}
                                    modules={modules}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="cat-feature-sec">
                        <ul className="category">
                            <h3>Categories</h3>
                            <div className="cat-list">
                                {categories.map((category) => (
                                    <li key={category._id}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={category._id}
                                                onChange={handleCheckboxChange}
                                                checked={selectedCategories.includes(category._id)}
                                            />
                                            {category.name}
                                        </label>
                                    </li>
                                ))}
                            </div>
                            <div className="form">
                                <div>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="New Category"
                                    />
                                    <button type="button" onClick={handleAddCategory}>
                                        Add
                                    </button>
                                </div>
                            </div>
                        </ul>
                        <div className="tags">
                            <Tags tags={tags} setTags={setTags} />
                        </div>
                        <div
                            className="feature-image"
                            onClick={() => {
                                setSelectingFor("feature");
                                setShowGallery(true);
                            }}
                        >
                            {featureImage ? <img src={featureImage} alt="Feature" /> : <h3>Feature Image</h3>}
                        </div>
                    </div>
                </form>
            </div>
            {showGallery && (
                <Gallery
                    setShowGallery={setShowGallery}
                    images={uploadedImages}
                    onSelectImage={handleSelectImage}
                />
            )}
        </div>
    );
};

export default PostForm;