import React from 'react'
import './Unable.scss'

const Unable = () => {
  return (
    <div className='unable-wrapper'>
      <h2>Access Denied</h2>
      <p>You do not have permission to view this page.</p>
    </div>
  )
}

export default Unable