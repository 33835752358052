import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import StoreContext from '../contexts/StoreContext'

const ProtectDashboard = ({role}) => {

    const { state } = useContext(StoreContext);
    const { userInfo } = state;

    if (userInfo) {
        return <Outlet />
    }else {
        return <Navigate to='/login'/>
    }
}

export default ProtectDashboard