import React, { useState, useEffect } from 'react';
import './Category.scss';
import { Link } from 'react-router-dom';
import { GrView } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import axios from 'axios';
import toast from 'react-hot-toast';

const Category = () => {
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');
    const [description, setDescription] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [categoryIdToEdit, setCategoryIdToEdit] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`https://api.hawanews.online/api/v1/categories`);
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error('Error fetching categories');
        }
    };

  useEffect(() => {
    const storedUser = localStorage.getItem('userInfo');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  if (!user) return <div>Please Login</div>

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const newCategory = { name, slug, description };
            if (editMode) {
                // Update existing category
                await axios.put(`https://api.hawanews.online/api/v1/categories/${categoryIdToEdit}`, newCategory);
                // Update category in the state
                setCategories(categories.map(category => category._id === categoryIdToEdit ? newCategory : category));
                setEditMode(false);
                setCategoryIdToEdit(null);
                toast.success('Category updated successfully');
            } else {
                // Add new category
                const response = await axios.post(`https://api.hawanews.online/api/v1/categories`, newCategory);
                setCategories([...categories, response.data.category]);
                toast.success('Category created successfully');
            }
            // Clear form fields
            setName('');
            setSlug('');
            setDescription('');
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error updating/creating category');
        }
    };

    const handleEdit = (category) => {
        // Set edit mode and populate form fields with category data
        setEditMode(true);
        setCategoryIdToEdit(category._id);
        setName(category.name);
        setSlug(category.slug);
        setDescription(category.description);
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this user ?')) {
            try {
                await axios.delete(`https://api.hawanews.online/api/v1/categories/${id}`);
                setCategories(categories.filter(category => category._id !== id));
                toast.success('Category deleted successfully');
            } catch (error) {
                console.error('Error deleting category:', error);
                toast.error('Error deleting category');
            }
        }
    };

    return (
        <div className='category-wrapper'>
            <div className="add-new-cat">
                <form onSubmit={handleSubmit}>
                    <div className="contentarea">
                        <div className="category">
                            <label htmlFor="category">Category</label>
                            <input
                                type="text"
                                placeholder='category name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="slug">
                            <label htmlFor="slug">Slug</label>
                            <input
                                type="text"
                                placeholder='slug here'
                                value={slug}
                                onChange={(e) => setSlug(e.target.value)}
                            />
                        </div>
                        <div className="description">
                            <label htmlFor="description">Description</label>
                            <input
                                type="text"
                                placeholder='description'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    <button type="submit">{editMode ? "Update Category" : "Add Category"}</button>
                </form>
            </div>
            <div className="cat-table">
                <div className="scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>NAME</th>
                                <th>SLUG</th>
                                <th>DESCRIPTION</th>
                                <th>COUNT</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody className='tbody'>
                            {categories.map((category, i) => (
                                <tr key={`${category._id}-${i}`}>
                                    <td>{i + 1}.</td>
                                    <td>{category.name}</td>
                                    <td>{category.slug}</td>
                                    <td>{category.description}</td>
                                    <td>{category.postCount}</td>
                                    <td>
                                        <div className='action-icons'>
                                            <Link to={`/categories/${category._id}`}><GrView /></Link>
                                            {user.role !== 'Editor' && (
                                                <>
                                                    <Link onClick={() => handleEdit(category)}><FaEdit /></Link>
                                                    <div className='trash' onClick={() => handleDelete(category._id)}><MdDelete /></div>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default Category;
