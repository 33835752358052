import React from 'react'
import './Profile.scss'
import { FaRegImage } from "react-icons/fa6";

const Profile = () => {
    return (
        <div className='profile-wrapper'>
            <div className="profile">
                <div className="profile-img">
                    <label htmlFor="img">
                        <div>
                            <span><FaRegImage /></span>
                            <span>Select Image</span>
                        </div>
                    </label>
                    <input type="file" id='img' />
                </div>
                <div className="info">
                    <p>Name: Test Name</p>
                    <p>Email: test-email@email.com</p>
                    <p>Category: All</p>
                </div>
            </div>
            <div className="pw-change">
                <div className="pw-input">
                    <p>Change Password</p>
                    <div className="input-sec">
                        <div className="old-pw">
                            <label htmlFor="password">Old password</label>
                            <input type="password" />
                        </div>
                        <div className="new-pw">
                            <label htmlFor="password">New password</label>
                            <input type="password" />
                        </div>
                    </div>
                    <button>Change</button>
                </div>
            </div>
        </div>
    )
}

export default Profile