import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import newsimg from './news.jpeg';
import { GrView } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import './Users.scss';
import axios from 'axios';
import toast from 'react-hot-toast';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [filterType, setFilterType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");

        if (!accessToken || !refreshToken) {
          toast.error("Missing authentication tokens");
          return;
        }

        const response = await axios.get(`https://api.hawanews.online/api/v1/users`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            refreshToken: refreshToken,
            page: currentPage,
            limit: usersPerPage,
            search: searchQuery,
          },
        });

        if (response.data && response.data.data) {
          const { users, pagination } = response.data.data;

          setUsers(users || []);
          if (pagination) {
            setTotalUsers(pagination.total || 0); // Correctly set the total number of users
            const calculatedTotalPages = Math.ceil((pagination.total || 0) / usersPerPage);
            console.log(`Total Users: ${pagination.total}, Users Per Page: ${usersPerPage}, Calculated Total Pages: ${calculatedTotalPages}`);
            setTotalPages(calculatedTotalPages);
          } else {
            console.error("Pagination data is missing in the response");
            setTotalPages(1);
            setTotalUsers(users.length);
          }
        } else {
          console.error("Invalid response format:", response.data);
        }
      } catch (error) {
        setError(error);
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [currentPage, usersPerPage, searchQuery]);

  const filteredUsers = users.filter(user => {
    if (filterType === 'Admin') {
      return user.role === 'Admin'
    } else if (filterType === 'Editor') {
      return user.role === 'Editor'
    } else if (filterType === 'Subscriber') {
      return user.role === 'Subscriber';
    } else {
      return true; // Show all users if no filter is selected
    }
  });

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user ?')) {
      try {
        await axios.delete(`https://api.hawanews.online/api/v1/users/${userId}`);
        setUsers(users.filter(user => user._id !== userId));
        toast.success('User deleted successfully!');
      } catch (error) {
        console.error("Error deleting user:", error);
        alert('Error deleting user');
      }
    }
  }

  const handleBulkDelete = async () => {
    if (window.confirm('Are you sure you want to delete selected users?')) {
      try {
        await Promise.all(selectedUsers.map(userId => axios.delete(`https://api.hawanews.online/api/v1/users/${userId}`)));
        setUsers(users.filter(user => !selectedUsers.includes(user._id)));
        setSelectedUsers([]);
        toast.success('Selected users deleted successfully!');
      } catch (error) {
        console.error("Error deleting selected users:", error);
        alert('Error deleting selected users');
      }
    }
  };

  const handleSelectUser = (userId) => {
    setSelectedUsers(prevSelected => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter(id => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedUsers.length === filteredUsers.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(filteredUsers.map(user => user._id));
    }
  };

  const isSelected = (userId) => selectedUsers.includes(userId);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleUsersPerPageChange = (event) => {
    setUsersPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };


  return (
    <div className='writer-wrapper'>
      <div className='writer-nav'>
        <p>Users({totalUsers})</p>
        <Link to='/dashboard/user/add'>Add User</Link>
      </div>
      <div className="user-filter">
        <select name="" id="" value={filterType} onChange={handleFilterChange}>
          <option value="ALL">All</option>
          <option value="Admin">Admins</option>
          <option value="Editor">Editors</option>
          <option value="Subscriber">Subscribers</option>
        </select>
        {selectedUsers.length > 0 && (
          <div className="action-buttons">
            <p>Selected ({selectedUsers.length})</p>
            <Link to={`/dashboard/user/update/${selectedUsers[0]}`}><FaEdit />Edit</Link>
            <Link className='trash' onClick={handleBulkDelete}><MdDelete />Delete</Link>
          </div>
        )}
        <input type="text" placeholder='search' value={searchQuery} onChange={handleSearchChange} />
      </div>
      <div className="user-table">
        <div className="scroll">
          <table>
            <thead>
              <tr>
                <th className='item-count'>No.</th>
                <th className='item-checkbox'><input type="checkbox" onChange={handleSelectAll} checked={selectedUsers.length === filteredUsers.length} /></th>
                <th className='item-name'>NAME</th>
                <th className='item-username'>USERNAME</th>
                <th className='item-role'>ROLE</th>
                <th className='item-image'>IMAGE</th>
                <th className='item-email'>EMAIL</th>
                <th className='item-action'>Action</th>
              </tr>
            </thead>
            <tbody className='tbody'>
              {
                filteredUsers.map((user, i) => (
                  <tr key={user._id}>
                    <td className='item-count'>{i + 1 + (currentPage - 1) * usersPerPage}.</td>
                    <td className='item-checkbox'><input type="checkbox" checked={isSelected(user._id)} onChange={() => handleSelectUser(user._id)} /></td>
                    <td className='item-name'>{user.fullName}</td>
                    <td className='item-username'>{user.username}</td>
                    <td className='item-role'>{user.role}</td>
                    <td className='item-image'><img src={user.avatar || newsimg} alt="" /></td>
                    <td className='item-email'>{user.email}</td>
                    <td className='item-action'>
                      <div className='action-icons'>
                        <Link to={`/dashboard/profile/${user._id}`}><GrView /></Link>
                        <Link to={`/dashboard/user/update/${user._id}`}><FaEdit /></Link>
                        <div className='trash' onClick={() => handleDelete(user._id)}><MdDelete /></div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className='pagination'>
        <div className='arrows'>
          <MdArrowBackIos onClick={() => handlePageChange(currentPage - 1)} />
        </div>
        <p>Users per page</p>
        <div className='users-size'>
          <select value={usersPerPage} onChange={handleUsersPerPageChange}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <p>{currentPage}/{totalPages} - of {totalUsers}</p>
        <div className='arrows'>
          <MdArrowForwardIos onClick={() => handlePageChange(currentPage + 1)} />
        </div>
      </div>
    </div>
  )
}

export default Users;
