import React from 'react'
import { TagsInput } from "react-tag-input-component";

const Tags = ({ tags, setTags }) => {
    return (
        <div className="tags-input-container">
            <h3>Add Tags</h3>
            <TagsInput
            classNames='tags-input'
                value={tags}
                onChange={setTags}
                name="tags"
                placeHolder="enter tag name"
            />
            <em>press enter to add new tag</em>
        </div>
    )
}

export default Tags