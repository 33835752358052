import React, { useEffect, useReducer } from 'react'
import StoreReducer from './StoreReducer.js'
import StoreContext from './StoreContext.js'
import { setUserInfo } from './StoreActions.js'


const StoreProvider = ({ children }) => {
  const initialState = {
    userInfo: {},
    accessToken: "",
    refreshToken: ""
  }

  const [state, dispatch] = useReducer(StoreReducer, initialState)

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      dispatch(setUserInfo(userInfo, localStorage.getItem('accessToken'), localStorage.getItem('refreshToken')));
    }
  }, [dispatch]);


  return <StoreContext.Provider value={{ state, dispatch }}>
    {children}
  </StoreContext.Provider>
}

export default StoreProvider