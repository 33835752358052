const StoreReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload.userInfo,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case "CLEAR_USER_INFO":
      return {
        ...state,
        userInfo: {},
        accessToken: "",
        refreshToken: "",
      };
    case "SET_SUBSCRIBER_INFO":
      return {
        ...state,
        subscriberInfo: action.payload.subscriberInfo,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case "CLEAR_SUBSCRIBER_INFO":
      return {
        ...state,
        subscriberInfo: {},
        accessToken: "",
        refreshToken: "",
      };

    default:
      return state;
  }
};

export default StoreReducer;
