// import "./DashboardRoutes.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MainLayout from "./layout/MainLayout.jsx"
import AdminIndex from "./pages/AdminIndex.jsx";
import ProtectDashboard from "./middlewares/ProtectDashboard.jsx"
import ProtectRole from "./middlewares/ProtectRole.jsx";
import Unable from "./pages/Unable.jsx";
import AddUser from "./pages/AddUser.jsx";
import Users from "./pages/Users.jsx";
import News from "./pages/News.jsx";
import Profile from "./pages/Profile.jsx";
import EditorIndex from "./pages/EditorIndex.jsx";
import CreateNews from "./pages/CreateNews.jsx";
import { useContext, useEffect, useState } from "react";
import StoreContext from "./contexts/StoreContext.js";
import Category from "./pages/Category.jsx";
import UpdateNews from "./pages/UpdateNews.jsx"
import UpdateUser from "./pages/UpdateUser.jsx";

function DashboardRoutes() {
  const {state} = useContext(StoreContext)
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('userInfo');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
      <Routes>
      <Route path="/dashboard" element={<ProtectDashboard />}>
        <Route path="" element={<MainLayout />}>
          <Route
            path=""
            element={
              user.role === "Admin" ? (
                <Navigate to="/dashboard/admin" />
              ) : (
                <Navigate to="/dashboard/editor" />
              )
            }
          />
          <Route path="unable-access" element={<Unable />} />
          <Route path="news" element={<News />} />
          <Route path="profile" element={<Profile />} />
          <Route path="news/create" element={<CreateNews />} />
          <Route path="news/edit/:postId" element={<UpdateNews />}/>
          <Route path="categories" element={<Category />} />

          <Route path="" element={<ProtectRole role="Admin" />}>
            <Route path="admin" element={<AdminIndex />} />
            <Route path="user/add" element={<AddUser />} />
            <Route path="user/update/:userId" element={<UpdateUser />} />
            <Route path="users" element={<Users />} />
          </Route>

          <Route path="" element={<ProtectRole role="Editor" />}>
            <Route path="editor" element={<EditorIndex />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default DashboardRoutes;
