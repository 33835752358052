import React, { useEffect, useState } from 'react';
import './EditorIndex.scss';
import { Link, useNavigate } from 'react-router-dom';
import { GrView } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import axios from 'axios';

const UserIndex = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [filterType, setFilterType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPosts, setSelectedPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`https://api.hawanews.online/api/v1/posts`, {
          params: {
            page: currentPage,
            limit: postsPerPage,
            search: searchQuery,
          }
        });

        console.log('API Response:', response.data);

        if (response.data && response.data.data) {
          const { posts, pagination } = response.data.data;

          setPosts(posts || []);
          setTotalPosts(pagination.total); // Correctly set the total number of posts
          const calculatedTotalPages = Math.ceil(pagination.total / postsPerPage);
          console.log(`Total Posts: ${pagination.total}, Posts Per Page: ${postsPerPage}, Calculated Total Pages: ${calculatedTotalPages}`);
          setTotalPages(calculatedTotalPages);

        } else {
          console.error("Invalid response format:", response.data);
        }
      } catch (error) {
        setError(error);
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [currentPage, postsPerPage, searchQuery]);

  const filteredPosts = posts.filter(post => {
    if (filterType === 'published') {
      return post.isPublished;
    } else if (filterType === 'draft') {
      return !post.isPublished;
    } else if (filterType === 'pending') {
      return post.status === 'pending';
    } else {
      return true; // Show all posts if no filter is selected
    }
  });

  const handleSelectPost = (postId) => {
    setSelectedPosts(prevSelected => {
      if (prevSelected.includes(postId)) {
        return prevSelected.filter(id => id !== postId);
      } else {
        return [...prevSelected, postId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedPosts.length === filteredPosts.length) {
      setSelectedPosts([]);
    } else {
      setSelectedPosts(filteredPosts.map(post => post._id));
    }
  };

  const isSelected = (postId) => selectedPosts.includes(postId);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePostsPerPageChange = (event) => {
    setPostsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };



  return (
    <div className='news-content-wrapper'>
      <div className='news-top'>
        <p>News ({totalPosts})</p>
        <Link to='/dashboard/news/create'>Create News</Link>
      </div>
      <div className="news-filter">
        <select name="" id="" value={filterType} onChange={handleFilterChange}>
          <option value="all">All</option>
          <option value="published">Published</option>
          <option value="draft">Draft</option>
          <option value="pending">Pending</option>
        </select>
        {selectedPosts.length > 0 && (
          <div className="action-buttons">
            <p>Selected ({selectedPosts.length})</p>
            <Link to={`/dashboard/news/edit/${selectedPosts[0]}`}><FaEdit />Edit</Link>
          </div>
        )}
        <input type="text" placeholder='search' value={searchQuery} onChange={handleSearchChange} />
      </div>
      <div className="news-table">
        <div className="scroll">
          <table>
            <thead>
              <tr>
                <th className='item-count'>No.</th>
                <th className='item-checkbox'><input type="checkbox" onChange={handleSelectAll} checked={selectedPosts.length === filteredPosts.length} /></th>
                <th className='item-title'>TITLE</th>
                <th className='item-author'>AUTHOR</th>
                <th className='item-category'>CATEGORY</th>
                <th className='item-date'>PUBLISHED DATE</th>
                <th className='item-status'>STATUS</th>
                <th className='item-action'>Action</th>
              </tr>
            </thead>
            <tbody className='tbody'>
              {filteredPosts.map((post, i) => (
                <tr key={post._id}>
                  <td className='item-count'>{i + 1 + (currentPage - 1) * postsPerPage}.</td>
                  <td className='item-checkbox'><input type="checkbox" checked={isSelected(post._id)} onChange={() => handleSelectPost(post._id)} /></td>
                  <td className='item-title'>{post.title}</td>
                  <td className='item-author'>{post.user.fullName}</td>
                  <td className='item-category'>{post.categories.map(category => category.name).join(', ')}</td>
                  <td className='item-date'>{new Date(post.createdAt).toLocaleDateString()}</td>
                  <td className='item-status'>{post.isPublished ? "Published" : "Draft"}</td>
                  <td className='item-action'>
                    <div className='action-icons'>
                      <Link to={`/post/${post._id}`}><GrView /></Link>
                      <Link to={`/dashboard/news/edit/${post._id}`}><FaEdit /></Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='pagination'>
        <div className='arrows'>
          <MdArrowBackIos onClick={() => handlePageChange(currentPage - 1)} />
        </div>
        <p>News per page</p>
        <div className='news-size'>
          <select value={postsPerPage} onChange={handlePostsPerPageChange}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <p>{currentPage}/{totalPages} - of {totalPosts}</p>
        <div className='arrows'>
          <MdArrowForwardIos onClick={() => handlePageChange(currentPage + 1)} />
        </div>
      </div>
    </div>
  );
};

export default UserIndex;
