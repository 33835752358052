import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './Login.scss'
import logo from './tc-logo.png'
import axios from 'axios';
import toast from 'react-hot-toast';
import StoreContext from '../contexts/StoreContext.js';
import { setUserInfo } from '../contexts/StoreActions.js';


const Login = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const { dispatch } = useContext(StoreContext);


  const handleLogin = async (event) => {
    event.preventDefault();

    const data = {
      username: username,
      password: password
    };

    try {
      setLoader(true)
      const response = await axios.post(`https://api.hawanews.online/api/v1/users/login`, data);
      const result = response.data;

      if (result.success) {
        setLoader(false)
        localStorage.setItem('accessToken', result.data.accessToken);
        localStorage.setItem('refreshToken', result.data.refreshToken);

        const userInfo = result.data.user

        localStorage.setItem('userInfo', JSON.stringify(userInfo)); // Save as userInfo

        toast.success('Login Successfull !')
        navigate('/dashboard');

        // Dispatch the action to update context state
        dispatch(setUserInfo(userInfo, result.data.accessToken, result.data.refreshToken));
        navigate('/dashboard');
      }
    } catch (error) {
      setLoader(false)
      toast.error('Invalid email/username or password');
      console.error('Login error:', error);
    }
  };

  return (
    <div className='login-wrapper'>
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <form onSubmit={handleLogin}>
        <div className="inputarea">
          <div className="username">
            <label htmlFor="username">Username or Email</label>
            <input id='username' name='username' type="text" value={username} onChange={(e) => setUsername(e.target.value)} required placeholder='username here' />
          </div>
          <div className="password">
            <label htmlFor="password">Password</label>
            <input id='password' name='password' type="password" onChange={(e) => setPassword(e.target.value)} required placeholder='password here' />
          </div>
        </div>
        <button type='submit' disabled={loader}>{loader ? 'loading ...' : 'Login'}</button>
      </form>
    </div>
  )
}

export default Login