import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.scss'
import logo from "./tc-logo.png";
import { MdDashboard, MdLogout } from "react-icons/md";
import { FaNewspaper, FaUserPlus, FaUser } from "react-icons/fa6";
import { HiUsers } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import StoreContext from '../contexts/StoreContext';
import axios from 'axios';


const Sidebar = ({ isVisible, onClose }) => {

  const { state } = useContext(StoreContext)
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('userInfo');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userInfo');
    navigate("/login");
  }

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!accessToken || !refreshToken) {
      alert.error("Missing authentication tokens");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`https://api.hawanews.online/api/v1/posts/upload-backup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          refreshToken: refreshToken,
        },
      });

      if (response.status === 200) {
        alert('CSV file uploaded successfully!');
      } else {
        alert('Failed to upload CSV file.');
      }
    } catch (error) {
      console.error('Error uploading CSV file:', error);
      alert('Error uploading CSV file. Please try again.');
    }
    finally {
      setLoading(false);
    }
  };



  return (
    <div className={`sidebar ${isVisible ? 'visible' : 'hidden'}`}>
      <button className="close-btn" onClick={onClose}>
        <IoClose />
      </button>
      <div className="logo">
        <Link to={`https://techchuli.com/`}><img src={logo} alt="Logo" /></Link>
      </div>
      <div className='site-view'>
        <Link to={`https://hawanews.online/`}>View Website</Link>
      </div>
      <ul className="nav-list" onClick={onClose}>
        {
          user.role === "Admin" ? <>
            <li>
              <Link to="admin"><MdDashboard />Dashboard</Link>
            </li>
            <li>
              <Link to="user/add"><FaUserPlus />Add User</Link>
            </li>
            <li>
              <Link to="users"><HiUsers />Users</Link>
            </li>
          </> : <>
            <li>
              <Link to="editor"><MdDashboard />Dashboard</Link>
            </li>
            <li>
              <Link to="news/create"><FaNewspaper />Add News</Link>
            </li>
          </>
        }
        <li>
          <Link to="news"><FaNewspaper />News</Link>
        </li>
        <li>
          <Link to="categories"><FaNewspaper />Categories</Link>
        </li>
        <li>
          <Link to="profile"><FaUser /> Profile</Link>
        </li>
        <li>
          <label className="file-upload">
            <FaUser /> Upload News
            <input type="file" onChange={handleFileUpload} accept=".CSV" />
          </label>
          {loading && <div className="uploading-overlay">
            <span className="uploading-text">Uploading files, Please wait!</span>
          </div>}
        </li>
        <li className='logout'>
          <Link onClick={handleLogout}>
            <MdLogout /> Logout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
