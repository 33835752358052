import React from 'react';
import PostForm from './PostForm';

const CreatePost = () => {
    return (
        <div>
            <PostForm isEditMode={false} />
        </div>
    );
};

export default CreatePost;