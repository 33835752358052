import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import DashboardRoutes from "./DashboardRoutes.js";
import Login from "./pages/Login.jsx";

const AppContent = () => {
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith("/dashboard");

  const isLoginRoute = location.pathname === "/login";

  if (isLoginRoute) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
      </Routes>
    );
  }

  return isDashboardRoute ? <DashboardRoutes /> : <Login />;
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;